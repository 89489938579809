import React from 'react';
import ReactDOM from 'react-dom';
import Player from 'griffith';

export default class VideoPlayer extends React.Component {
  componentWillMount() {
    this.props.sources.hd.play_url += window.location.search.split('?')[1];
    //this.props.sources.hd.play_url = 'http://fpray.com/onedrive/movie/HF1.mp4';
    console.log(this.props.sources.hd.play_url);
    console.log(window.location.search);
  }

  render() {
    let divCss = {
      width: '1080px',
      height: '720px',
      margin: '0 auto'
    };
    return (
      <div style={divCss}>
        <Player {...this.props} />
      </div>
    );
  }
}

function GetUrlRelativePath() {
  var url = document.location.toString();
  var arrUrl = url.split('//');
  var start = arrUrl[1].indexOf('/');
  var relUrl = arrUrl[1].substring(start); //stop省略，截取从start开始到结尾的所有字符

  if (relUrl.indexOf('?') !== -1) {
    relUrl = relUrl.split('?')[0];
  }
  return relUrl;
}

const duration = 182;
const griffithOptions = {
  id: 'OneDrive',
  title: 'Powered by griffith',
  cover: 'https://i.loli.net/2019/12/27/5nDxiQtsZUb76VA.jpg',
  duration,
  sources: {
    hd: {
      bitrate: 10000,
      size: 46723282,
      duration,
      format: 'mp4',
      width: 500,
      height: 300,
      play_url: ''
    }
  }
};

ReactDOM.render(
  <VideoPlayer {...griffithOptions} />,
  document.getElementById('root')
);
